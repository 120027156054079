<!-- prettier-ignore -->
<template>
  <TransitionRoot :show="open" as="template" appear>
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-25 dark:bg-opacity-40 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
          <DialogPanel class="mx-auto max-w-xl transform rounded-xl bg-white dark:bg-gray-700 p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox @update:model-value="onSelect">
              <div class="relative">
                <Icon name="search-loop" styles="pointer-events-none absolute left-4 top-[13px] h-4 w-4 text-gray-400" />
                <ComboboxInput :display-value="() => query" class="w-full pl-11 pr-4 py-2.5 focus:ring-0 sm:text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Type to search for documents ..."  @change="query = $event.target.value" />
              </div>

              <ComboboxOptions v-if="filteredDocuments.length > 0" static class="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-900 dark:text-gray-300">
                <ComboboxOption v-for="doc in filteredDocuments" v-slot="{ active }" :key="doc._id" :value="doc" as="template">
                  <li :class="['flex cursor-default select-none rounded-md px-4 py-2', active && 'bg-gray-600 text-white']">
                    <div :class="['flex h-10 w-10 flex-none items-center justify-center rounded-lg', colorForDocument(doc.type)]">
                      <Icon :name="iconForDocument(doc.type)" styles="mx-auto h-4 w-4 text-white" />
                    </div>
                    <div class="ml-4 flex flex-auto flex-col">
                      <div :class="['text-sm font-medium flex flex-1 items-center', active ? 'text-white' : 'text-gray-300']">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div v-html="highlightKeywords(query, doc.title)"></div>
                      </div>
                      <div v-if="DocumentType.isNote(doc.type)" :class="['text-sm', active ? 'text-gray-300' : 'text-gray-500']">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div v-html="shortenAndHighlightKeywords(query, doc.fullText ?? '')"></div>
                      </div>
                    </div>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>

              <div v-if="filteredDocuments.length === 0" class="px-4 py-14 text-center sm:px-14">
                <Icon name="search-loop" styles="mx-auto h-6 w-6 text-gray-400" />
                <p class="mt-4 text-sm text-gray-900 dark:text-gray-500">No documents found using that search term.</p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import DOMPurify from "dompurify";
import { type IDocument, DocumentType } from "~/types/Document";
import emitter from "~/utils/mitt";

const open = ref(false);
const query = ref("");
const filteredDocuments = ref<IDocument<DocumentType>[]>([]);

//
// Load data
//
const loadDocuments = async (query: string) => {
  if (query.length > 0) {
    console.log("loadDocuments", query);
    const { data, error } = await useFetch("/api/documents/search", {
      query: {
        search: query,
      },
      transform: (data: IDocument<DocumentType>[] | null) => data,
    });
    if (error.value) {
      console.error(error.value);
    }
    // @ts-ignore
    filteredDocuments.value = data.value ?? [];
  }
};

//
// Functions
//
watch(query, () => {
  loadDocuments(query.value);
});

const pathForDocument = (type: DocumentType) => {
  switch (type) {
    case DocumentType.note:
      return "/note";
    case DocumentType.mindmap:
      return "/mindmap";
    case DocumentType.board:
      return "/board";
  }
};

const iconForDocument = (type: DocumentType) => {
  switch (type) {
    case DocumentType.note:
      return "file";
    case DocumentType.mindmap:
      return "share-nodes";
    case DocumentType.board:
      return "lightbulb";
  }
};

const colorForDocument = (type: DocumentType) => {
  switch (type) {
    case DocumentType.note:
      return "bg-blue-700";
    case DocumentType.mindmap:
      return "bg-green-700";
    case DocumentType.board:
      return "bg-purple-700";
  }
};

// TODO
// move to utils
const highlightKeywords = (query: string, text: string) => {
  if (!query.trim()) {
    return text;
  }

  // Split the text into words and non-word characters
  const tokens = text.split(/(\W+)/);
  const words = query.trim().split(/\s+/);

  // Create a set for faster lookup
  const searchWords = new Set(words.map((word) => word.toLowerCase()));

  // Process each token
  const result = tokens
    .map((token) => {
      // Check if the token is a word and if it's part of the search words
      if (/\w+/.test(token) && searchWords.has(token.toLowerCase())) {
        return `<span class="font-medium text-yellow-400">${token}</span>`;
      } else {
        return token;
      }
    })
    .join("");

  return DOMPurify.sanitize(result);
};

// TODO
// move to utils
const shortenAndHighlightKeywords = (
  query: string,
  text: string,
  maxLength = 200,
) => {
  // Early return if text is shorter than maxLength
  if (text.length <= maxLength) {
    return highlightKeywords(query, text);
  }

  const tokens = text.split(/(\W+)/);
  const words = query.trim().split(/\s+/);
  const searchWords = new Set(words.map((word) => word.toLowerCase()));

  const highlightedTokens = tokens.map((token, _index) => {
    return {
      text:
        /\w+/.test(token) && searchWords.has(token.toLowerCase())
          ? `<span class="font-medium text-yellow-400">${token}</span>`
          : token,
      isHighlighted: /\w+/.test(token) && searchWords.has(token.toLowerCase()),
    };
  });

  let bestSegment = "";
  let maxHighlights = 0;

  for (let i = 0; i < highlightedTokens.length; i++) {
    if (!highlightedTokens[i].isHighlighted) continue; // Skip non-highlighted starting points

    let segment = "";
    let highlightsCount = 0;
    let length = 0;

    // Extend segment backwards to start of text or until maxLength is reached
    for (let j = i; j >= 0 && length < maxLength; j--) {
      segment = highlightedTokens[j].text + segment;
      length += highlightedTokens[j].text.length;
      if (highlightedTokens[j].isHighlighted) {
        highlightsCount++;
      }
    }

    // Extend segment forwards to end of text or until maxLength is reached
    for (
      let j = i + 1;
      j < highlightedTokens.length && length < maxLength;
      j++
    ) {
      segment += highlightedTokens[j].text;
      length += highlightedTokens[j].text.length;
      if (highlightedTokens[j].isHighlighted) {
        highlightsCount++;
      }
    }

    if (
      highlightsCount > maxHighlights ||
      (highlightsCount === maxHighlights && segment.length < bestSegment.length)
    ) {
      bestSegment = segment;
      maxHighlights = highlightsCount;
    }
  }

  return DOMPurify.sanitize(
    bestSegment || highlightKeywords(query, text.substring(0, maxLength)),
  ); // Fallback to the beginning of the text if no highlights found
};

const onSelect = async (doc: IDocument<DocumentType>) => {
  const result = filteredDocuments.value.find((d) => d._id === doc._id);
  if (!result || !result._key || !result.type) return;
  await navigateTo(`${pathForDocument(result.type)}-${result._key}`);
  setTimeout(() => {
    open.value = false;
    filteredDocuments.value = [];
  }, 300);
};

emitter.on("show-global-search", (value) => {
  open.value = value as boolean;
});
</script>
